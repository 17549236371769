import React from "react";
import ScrollableAnchor from "react-scrollable-anchor";

import Input from "../../components/Input";
import Select from "../../components/Select";
import Radio from "../../components/Radio";
import Checkbox from "../../components/Checkbox";
import Textarea from "../../components/Textarea";

const Forms = () => (
  <ScrollableAnchor id="forms">
    <section className="py-3 mb-4">
      <h2 className="h4 font-weight-normal">Forms</h2>
      <hr className="mt-1 mb-4" />

      <div className="row">
        <div className="col-lg-6 mb-5">
          <label>Simple input</label>
          <div className="mb-3">
            <Input placeholder="Enter your address..." />
          </div>
          <div className="mb-3">
            <label>Active</label>
            <Input placeholder="Enter your address..." active />
          </div>
          <div className="mb-3">
            <label>Valid input</label>
            <Input placeholder="Enter your address..." valid />
          </div>
          <div className="mb-3">
            <label>Invalid input</label>
            <Input placeholder="Enter your address..." invalid />
          </div>
          <div className="mb-3">
            <label>Disabled</label>
            <Input placeholder="Enter your address..." disabled />
          </div>
          <div className="mb-3">
            <label>Textarea</label>
            <Textarea placeholder="Provide any additional information..." />
          </div>
          <div className="mb-3">
            <label>
              Textarea <small className="text-mid-gray">(resizable)</small>
            </label>
            <Textarea
              placeholder="Provide any additional information..."
              large
              resize
            />
          </div>
        </div>

        <div className="col-lg-5 offset-lg-1 mb-5">
          <div className="mb-4">
            <label>Select</label>
            <Select placeholder="Select your country">
              <Select.Option>Afghanistan</Select.Option>
              <Select.Option>Aland Islands</Select.Option>
              <Select.Option>Albania</Select.Option>
              <Select.Option>Algeria</Select.Option>
              <Select.Option>American Samoa</Select.Option>
              <Select.Option>Andorra</Select.Option>
              <Select.Option>Angola</Select.Option>
              <Select.Option>Anguilla</Select.Option>
              <Select.Option>Antigua</Select.Option>
              <Select.Option>Argentina</Select.Option>
            </Select>
          </div>
          <div className="mb-4">
            <label>Radio</label>
            <Radio name="fruit" label="Apples" />
            <Radio name="fruit" label="Bananas" />
            <Radio name="fruit" label="Kiwis" />
            <Radio name="fruit" label="Melons" />
          </div>
          <div className="mb-4">
            <label>Checkboxes</label>
            <Checkbox name="veggies" label="Asparagus" />
            <Checkbox name="veggies" label="Broccoli" />
            <Checkbox name="veggies" label="Cauliflower" />
            <Checkbox name="veggies" label="Eggplant" />
          </div>
        </div>
      </div>
    </section>
  </ScrollableAnchor>
);

export default Forms;
