import React from "react";
import cx from "classnames";
import ScrollableAnchor from "react-scrollable-anchor";
import Swatch from "../../components/Swatch";

const Compact = ({ className, label, sublabel }) => (
  <div className="d-flex w-100 col-sm-6 col-lg-3 p-0 mb-2 font-size-6 text-dark-gray">
    <div className={cx("py-4 w-40", className)} />
    <div className="w-60 px-3 d-flex flex-column justify-content-center">
      <div className="text-semibold">{label}</div>
      <div className="text-mid-gray">{sublabel}</div>
    </div>
  </div>
);

const Colors = () => (
  <ScrollableAnchor id="colors">
    <section className="py-3 mb-3">
      <h2 className="h4 font-weight-normal">Functional Colors</h2>
      <hr className="mt-1 mb-4" />

      <p className="h6 font-weight-normal">Main Colors</p>
      <div className="container mb-4">
        <div className="row mb-4">
          <Swatch className="mr-2 bg-blue">
            <Swatch.Label>Main CTA Color</Swatch.Label>
            <Swatch.Sublabel>blue</Swatch.Sublabel>
          </Swatch>
          <Swatch className="mr-2 bg-navy">
            <Swatch.Label>Main Text Color</Swatch.Label>
            <Swatch.Sublabel>navy</Swatch.Sublabel>
          </Swatch>
          <Swatch className="mr-2 bg-mid-gray">
            <Swatch.Label>Secondary Text Color</Swatch.Label>
            <Swatch.Sublabel>gray</Swatch.Sublabel>
          </Swatch>
          <Swatch className="mr-2 bg-white border">
            <Swatch.Label>Primary Background Color</Swatch.Label>
            <Swatch.Sublabel>white</Swatch.Sublabel>
          </Swatch>
        </div>
      </div>

      <p className="h6 font-weight-normal">Secondary Colors</p>
      <div className="container mb-5 pb-3">
        <div className="row mb-4">
          <Swatch className="mr-2 bg-green">
            <Swatch.Label>Success</Swatch.Label>
            <Swatch.Sublabel>green</Swatch.Sublabel>
          </Swatch>
          <Swatch className="mr-2 bg-red">
            <Swatch.Label>Failed</Swatch.Label>
            <Swatch.Sublabel>red</Swatch.Sublabel>
          </Swatch>
          <Swatch className="mr-2 bg-yellow">
            <Swatch.Label>Highlights</Swatch.Label>
            <Swatch.Sublabel>yellow</Swatch.Sublabel>
          </Swatch>
          <Swatch className="mr-2 bg-near-white border">
            <Swatch.Label>Secondary Background Color</Swatch.Label>
            <Swatch.Sublabel>near-white</Swatch.Sublabel>
          </Swatch>
        </div>
      </div>

      <h2 className="h4 font-weight-normal">Color Library</h2>
      <hr className="mt-1 mb-4" />
      <div className="container mb-4">
        <div className="row mb-4">
          <Compact className="bg-black" label="black" sublabel="#000000" />
          <Compact
            className="bg-near-black"
            label="near-black"
            sublabel="#1E1E28"
          />
          <Compact
            className="bg-dark-gray"
            label="dark-gray"
            sublabel="#35353F"
          />
          <Compact
            className="bg-mid-gray"
            label="mid-gray"
            sublabel="#F8B92A"
          />
          <Compact className="bg-gray" label="gray" sublabel="#9E9EA8" />
          <Compact className="bg-silver" label="silver" sublabel="#B8BAC0" />
          <Compact
            className="bg-light-gray"
            label="light-gray"
            sublabel="#E6E6E7"
          />
          <Compact
            className="bg-near-white"
            label="near-white"
            sublabel="#F5F5F9"
          />
          <Compact className="bg-white" label="white" sublabel="#FFFFFF" />
          <Compact
            className="bg-washed-red"
            label="washed-red"
            sublabel="#FFF4F4"
          />
          <Compact
            className="bg-washed-blue"
            label="washed-blue"
            sublabel="#F0FAFF"
          />
          <Compact
            className="bg-washed-yellow"
            label="washed-yellow"
            sublabel="#FFFCEF"
          />
          <Compact className="bg-blue" label="blue" sublabel="#1D539F" />
          <Compact className="bg-red" label="red" sublabel="#E73427" />
          <Compact className="bg-yellow" label="yellow" sublabel="#F8B92A" />
          <Compact className="bg-green" label="green" sublabel="#FFFCEF" />
        </div>
      </div>
    </section>
  </ScrollableAnchor>
);

export default Colors;
