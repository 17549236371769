import React from "react";
import cx from "classnames";

const Option = ({ active, children, selected, className, light }) => (
  <button className={cx("option", { active: active, light: light }, className)}>
    {children}
  </button>
);
const Selectable = ({ children, className }) => (
  <div className={cx("selectable", className)}>{children}</div>
);

Selectable.Option = Option;

export default Selectable;
