import React from "react";
import cx from "classnames";

const Checkbox = ({ label, name, className }) => (
  <div className={cx("checkbox", className)}>
    <input type="checkbox" name={name} />
    <label className="mb-0 ml-1">{label}</label>
  </div>
);

export default Checkbox;
