import React from "react";
import ScrollableAnchor from "react-scrollable-anchor";

import Badge from "../../components/Badge";

const Badges = () => (
  <ScrollableAnchor id="badges">
    <section className="py-3 mb-5">
      <h2 className="h4 font-weight-normal">Badges</h2>
      <hr className="mt-1 mb-4" />
      <div className="container">
        <div className="row">
          <Badge className="mr-2">Default</Badge>
          <Badge className="mr-2" primary>
            Primary
          </Badge>
          <Badge className="mr-2" success>
            Success
          </Badge>
          <Badge className="mr-2" warning>
            Warning
          </Badge>
          <Badge className="mr-2" info>
            Info
          </Badge>
          <Badge className="mr-2" danger>
            Danger
          </Badge>
        </div>
      </div>
    </section>
  </ScrollableAnchor>
);

export default Badges;
