import React from "react";

const Header = () => (
  <header className="header bg-navy bg-navy text-light p-5">
    <div className="container py-5 px-md-5 mb-5">
      <h1 className="font-size-1 text-lighter text-yellow lh-title mb-2 space-mono">
        Pallet Wrap CSS<span className="font-size-4 ml-3">v0.3.0</span>
      </h1>
      <h3
        className="font-weight-normal text-light-gray mb-4"
        style={{ maxWidth: "750px" }}
      >
        Resources and stylesheets for building interfaces with MLH's design
        system.
      </h3>
      <div className="space-mono text-yellow mt-2">
        <a
          className="text-yellow"
          href="https://github.com/MLH/pallet-wrap-css/archive/master.zip"
        >
          Download
        </a>
        <span className="mx-2">・</span>
        <a
          className="text-yellow"
          href="https://github.com/MLH/pallet-wrap-css"
        >
          Source
        </a>
      </div>
    </div>
  </header>
);

export default Header;
