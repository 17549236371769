import React from "react";
import ScrollableAnchor from "react-scrollable-anchor";

import Button from "../../components/Button";

const Compact = ({ item, label, sublabel, children, vertical }) => (
  <div
    className={`d-flex ${vertical &&
      "flex-column"} w-100 col-sm-6 col-lg-4 p-0 mb-4 font-size-6 text-dark-gray`}
  >
    {item}
    <div className="px-2 d-flex flex-column justify-content-center">
      {children}
    </div>
  </div>
);

Compact.Label = ({ children }) => (
  <div className="text-semibold">{children}</div>
);

Compact.Sublabel = ({ children }) => (
  <div className="text-mid-gray">{children}</div>
);

const examples = {
  primary: (
    <Button className="mr-2" primary>
      Continue
    </Button>
  ),
  primaryHover: (
    <Button className="mr-2" primary hover>
      Continue
    </Button>
  ),
  primaryDisabled: (
    <Button className="mr-2" primary disabled>
      Continue
    </Button>
  ),
  light: (
    <Button className="mr-2" light>
      Continue
    </Button>
  ),

  lightHover: (
    <Button className="mr-2" light hover>
      Continue
    </Button>
  ),
  lightDisabled: (
    <Button className="mr-2" light disabled>
      Continue
    </Button>
  ),
  dark: (
    <Button className="mr-2" dark>
      Continue
    </Button>
  ),
  darkHover: (
    <Button className="mr-2" dark hover>
      Continue
    </Button>
  ),
  darkDisabled: (
    <Button className="mr-2" dark disabled>
      Continue
    </Button>
  ),
  danger: (
    <Button className="mr-2" danger>
      Continue
    </Button>
  ),
  dangerHover: (
    <Button className="mr-2" danger hover>
      Continue
    </Button>
  ),
  dangerDisabled: (
    <Button className="mr-2" danger disabled>
      Continue
    </Button>
  ),
  dangerOutline: (
    <Button className="mr-2" danger outline>
      Continue
    </Button>
  ),
  dangerOutlineHover: (
    <Button className="mr-2" danger outline hover>
      Continue
    </Button>
  ),
  dangerOutlineDisabled: (
    <Button className="mr-2" danger outline disabled>
      Continue
    </Button>
  )
};

const Buttons = () => (
  <ScrollableAnchor id="buttons">
    <section className="py-3 mb-5">
      <h2 className="h4 font-weight-normal">Buttons</h2>
      <hr className="mt-1 mb-4" />

      <p className="h6 mb-3 font-weight-normal">Main Buttons</p>
      <div className="container">
        <div className="row mb-4">
          <Compact item={examples.primary}>
            <Compact.Label>Primary Button</Compact.Label>
            <Compact.Sublabel>.btn-primary</Compact.Sublabel>
          </Compact>
          <Compact item={examples.primaryHover}>
            <Compact.Label>Primary Hover</Compact.Label>
            <Compact.Sublabel>.btn-primary</Compact.Sublabel>
          </Compact>
          <Compact item={examples.primaryDisabled}>
            <Compact.Label>Primary Disabled</Compact.Label>
            <Compact.Sublabel>.btn-primary</Compact.Sublabel>
          </Compact>

          <Compact item={examples.light}>
            <Compact.Label>Secondary Button</Compact.Label>
            <Compact.Sublabel>.btn-light</Compact.Sublabel>
          </Compact>
          <Compact item={examples.lightHover}>
            <Compact.Label>Secondary Hover</Compact.Label>
            <Compact.Sublabel>.btn-light</Compact.Sublabel>
          </Compact>
          <Compact item={examples.lightDisabled}>
            <Compact.Label>Secondary Disabled</Compact.Label>
            <Compact.Sublabel>.btn-light</Compact.Sublabel>
          </Compact>
          <Compact item={examples.dark}>
            <Compact.Label>Tertiary Button</Compact.Label>
            <Compact.Sublabel>.btn-dark</Compact.Sublabel>
          </Compact>
          <Compact item={examples.darkHover}>
            <Compact.Label>Tertiary Hover</Compact.Label>
            <Compact.Sublabel>.btn-dark</Compact.Sublabel>
          </Compact>
          <Compact item={examples.darkDisabled}>
            <Compact.Label>Tertiary Disabled</Compact.Label>
            <Compact.Sublabel>.btn-dark</Compact.Sublabel>
          </Compact>
        </div>
      </div>

      <p className="h6 mb-3 font-weight-normal">Destructive Buttons</p>
      <div className="container">
        <div className="row mb-3">
          <Compact item={examples.danger}>
            <Compact.Label>Destructive Button</Compact.Label>
            <Compact.Sublabel>.btn-danger</Compact.Sublabel>
          </Compact>
          <Compact item={examples.dangerHover}>
            <Compact.Label>Destructive Button</Compact.Label>
            <Compact.Sublabel>.btn-danger</Compact.Sublabel>
          </Compact>
          <Compact item={examples.dangerDisabled}>
            <Compact.Label>Destructive Disabled</Compact.Label>
            <Compact.Sublabel>.btn-danger</Compact.Sublabel>
          </Compact>
          <Compact item={examples.dangerOutline}>
            <Compact.Label>Destructive Outline</Compact.Label>
            <Compact.Sublabel>.btn-outline-danger</Compact.Sublabel>
          </Compact>
          <Compact item={examples.dangerOutlineHover}>
            <Compact.Label>Destructive Outline Hover</Compact.Label>
            <Compact.Sublabel>.btn-outline-danger</Compact.Sublabel>
          </Compact>
          <Compact item={examples.dangerOutlineDisabled}>
            <Compact.Label>Destructive Outline Disabled</Compact.Label>
            <Compact.Sublabel>.btn-outline-danger</Compact.Sublabel>
          </Compact>
        </div>
      </div>
    </section>
  </ScrollableAnchor>
);

export default Buttons;
