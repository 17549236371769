import React from "react";
import cx from "classnames";

const Button = ({
  children,
  small,
  large,
  block,
  primary,
  danger,
  hover,
  light,
  dark,
  disabled,
  outline,
  className
}) => (
  <button
    className={cx(
      "btn",
      {
        "btn-sm": small,
        "btn-lg": large,
        "btn-block": block,
        "btn-primary": primary && !outline,
        "btn-danger": danger && !outline,
        "btn-outline-danger": danger && outline,
        "btn-light": light,
        "btn-dark": dark,
        hover: hover,
        disabled: disabled
      },
      className
    )}
    disabled={disabled}
  >
    {children}
  </button>
);

export default Button;
