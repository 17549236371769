import React from "react";
import cx from "classnames";

const Textarea = ({ className, placeholder, resize, large }) => (
  <textarea
    rows={large ? "6" : "3"}
    placeholder={placeholder}
    className={cx("input textarea", { resize: resize }, className)}
  ></textarea>
);

export default Textarea;
