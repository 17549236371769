import React from "react";
import ScrollableAnchor from "react-scrollable-anchor";

import Link from "../../components/Link";

const Links = () => (
  <ScrollableAnchor id="links">
    <section className="py-3 mb-5">
      <h2 className="h4 font-weight-normal">Links</h2>
      <hr className="mt-1 mb-4" />
      <div className="container">
        <div className="row">
          <div className="col-md-3 p-0 mb-3">
            <Link href="#">Text link (idle)</Link>
          </div>
          <div className="col-md-3 p-0 mb-3">
            <Link href="#" hover>
              Text link (hover)
            </Link>
          </div>
          <div className="col-md-3 p-0 mb-3">
            <Link href="#" disabled>
              Text link (disabled)
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 p-0 mb-3">
            <Link href="#" danger>
              Destructive (idle)
            </Link>
          </div>
          <div className="col-md-3 p-0 mb-3">
            <Link href="#" danger hover>
              Destructive (hover)
            </Link>
          </div>
          <div className="col-md-3 p-0 mb-3">
            <Link href="#" danger disabled>
              Destructive (disabled)
            </Link>
          </div>
        </div>
      </div>
    </section>
  </ScrollableAnchor>
);

export default Links;
