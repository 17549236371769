import React from "react";
import cx from "classnames";

const Card = ({ children, light, dark, className }) => (
  <div
    className={cx(
      "card",
      {
        light: light,
        dark: dark
      },
      className
    )}
  >
    <>{children}</>
  </div>
);

export default Card;
