import React from "react";
import ScrollableAnchor from "react-scrollable-anchor";

import Card from "../../components/Card";

const Cards = () => (
  <ScrollableAnchor id="cards">
    <section className="py-3 mb-5">
      <h2 className="h4 font-weight-normal">Cards</h2>
      <hr className="mt-1 mb-4" />

      <div className="row">
        <div className="col-lg-4">
          <Card className="mb-3">
            <h5 className="title h6 text-semibold mb-0">
              This is a default card
            </h5>
            <p className="text-mid-gray mb-0">Use this for more information.</p>
          </Card>
        </div>
        <div className="col-lg-4">
          <Card className="mb-3" light>
            <h5 className="title h6 mb-0">This is a light card</h5>
            <p className="mb-0">Use this for more information.</p>
          </Card>
        </div>
        <div className="col-lg-4">
          <Card className="mb-3" dark>
            <h5 className="title h6 mb-0">This is a primary card</h5>
            <p className="mb-0">Use this for more information.</p>
          </Card>
        </div>
      </div>
    </section>
  </ScrollableAnchor>
);

export default Cards;
