import React from "react";
import cx from "classnames";

const Link = ({ href, className, children, hover, danger, disabled }) => (
  <a
    className={cx(
      "link",
      { hover: hover, "link-danger": danger, disabled: disabled },
      className
    )}
    href={href}
    disabled={disabled}
  >
    {children}
  </a>
);

export default Link;
