import React from "react";
import ScrollableAnchor from "react-scrollable-anchor";
import Selectable from "../../components/Selectable";

const Selectables = () => (
  <ScrollableAnchor id="selectables">
    <section className="py-3 mb-4">
      <h2 className="h4 font-weight-normal">Selectables</h2>
      <hr className="mt-1 mb-4" />

      <div className="row">
        <div className="mb-4 col-lg-6">
          <label>Selectables</label>
          <Selectable>
            <Selectable.Option active>Apples</Selectable.Option>
            <Selectable.Option>Bananas</Selectable.Option>
            <Selectable.Option>Kiwis</Selectable.Option>
            <Selectable.Option>Melons</Selectable.Option>
            <Selectable.Option>Oranges</Selectable.Option>
            <Selectable.Option>Pineapples</Selectable.Option>
          </Selectable>
        </div>
        <div className="mb-4 col-lg-6">
          <label>
            Selectables <small className="text-mid-gray">(light)</small>
          </label>
          <Selectable>
            <Selectable.Option light active>
              Asparagus
            </Selectable.Option>
            <Selectable.Option light>Broccoli</Selectable.Option>
            <Selectable.Option light>Cauliflower</Selectable.Option>
            <Selectable.Option light>Eggplant</Selectable.Option>
            <Selectable.Option light>Tomatoes</Selectable.Option>
            <Selectable.Option light>Zucchini</Selectable.Option>
          </Selectable>
        </div>
      </div>
    </section>
  </ScrollableAnchor>
);

export default Selectables;
