import React from "react";
import cx from "classnames";

const Input = ({
  id,
  className,
  active,
  valid,
  invalid,
  type = "text",
  placeholder,
  disabled
}) => (
  <input
    id={id}
    type={type}
    placeholder={placeholder}
    className={cx(
      "input",
      {
        active: active,
        valid: valid,
        invalid: invalid
      },
      className
    )}
    disabled={disabled}
  />
);

export default Input;
