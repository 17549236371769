import React, { Component } from "react";

import Buttons from "./sections/components/Buttons";
import Forms from "./sections/components/Forms";
import Links from "./sections/components/Links";
import Selectables from "./sections/components/Selectables";
import Cards from "./sections/components/Cards";
import Badges from "./sections/components/Badges";

import Colors from "./sections/utilities/Colors";

import Footer from "./page/Footer";
import Header from "./page/Header";
import Navbar from "./page/Navbar";
import Sidebar from "./page/Sidebar";

class App extends Component {
  render() {
    return (
      <div>
        <Navbar />
        <div className="d-flex">
          <div
            className="bg-near-white border border-light-gray d-none d-lg-inline"
            style={{ minWidth: "260px" }}
          >
            <Sidebar />
          </div>
          <div className="w-100">
            <Header />
            <div className="container my-5 py-5 px-5">
              <Colors />
              <Buttons />
              <Links />
              <Badges />
              <Forms />
              <Selectables />
              <Cards />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default App;
