import React from "react";
import cx from "classnames";

const Badge = ({
  className,
  children,
  primary,
  success,
  danger,
  warning,
  info
}) => (
  <span
    className={cx(
      "badge",
      {
        "badge-primary": primary,
        "badge-success": success,
        "badge-warning": warning,
        "badge-info": info,
        "badge-danger": danger
      },
      className
    )}
  >
    {children}
  </span>
);

export default Badge;
