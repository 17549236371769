import React from "react";
import cx from "classnames";

const Swatch = ({ children, className, label, sublabel, value }) => (
  <div className="col-sm-6 col-lg-3 p-0 mb-2">
    <div
      className={cx("border-radius-2 p-3 mb-2", className)}
      style={{ height: "115px" }}
    />
    {children}
  </div>
);

Swatch.Label = ({ children }) => <div className="font-size-5">{children}</div>;

Swatch.Sublabel = ({ children }) => (
  <div className="font-size-6 text-mid-gray">{children}</div>
);

export default Swatch;
